export function initFrontendMisc() {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    });
}

function init() {
    const $body = $('body');
    const $html = $('html');
    
    // Make Tables Responsive
    $("main .text table").wrap("<div class='table-responsive'></div>");

    // Make Images Responsive
    $("main .text img").addClass("img-fluid");

    // Add link class
    $("main .text a:not(.bttn)").addClass("link");

    // Add a class to body when on top of viewport
    const showOnPx = 0;
    const backToTopButton = document.querySelector("body")

    const scrollContainer = () => {
        return document.documentElement || document.body;
    };

    document.addEventListener("scroll", () => {
        if (scrollContainer().scrollTop > showOnPx) {
            backToTopButton.classList.remove("top-of-page")
        } else {
            backToTopButton.classList.add("top-of-page")
        }
    });

    // Magnific Popup Lightbox - Image gallery block
    $('.block__list__gallery .list').each(function () { // the containers for all your galleries
      $(this).magnificPopup({
          delegate: 'a',
          type: 'image',
          mainClass: 'mfp-with-zoom',
          zoom: {
              enabled: true,
              duration: 150,
              easing: 'ease-in-out',
              opener: function (openerElement) {
                  return openerElement.is('img') ? openerElement : openerElement.find('img');
              }
          },
          tLoading: 'Laster...', // Text that is displayed during loading. Can contain %curr% and %total% keys
          gallery: {
              enabled: true,
              tPrev: 'Forrige (Venstre piltast)', // Alt text on left arrow
              tNext: 'Neste (Høyre piltast)', // Alt text on right arrow
              tCounter: 'Bilde %curr% av %total%'
          },
          image: {
              titleSrc: item => {
                  return item.el[0].querySelector('img').title;
              },
          },
      });
    });

    // Dropdown Menu
    jQuery('ul.sf-menu').superfish();

    // 100% vh bug
    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    
    // Mega Menu Dropdown
    $(".toggle-mega").click(function () {
        // If open
        if ($body.hasClass("mega--open")) {
            $body.alterClass('*--open', '');
            $(this).attr('aria-expanded', "false");
            $(".site__header__mega").slideUp();

            // If not open
        } else if (!$body.hasClass("mega--open")) {
            $body.alterClass('*--open', 'mega--open');
            $(".site__header__search").slideUp();

            $(".site__header__mega").slideDown();
            $(this).attr('aria-expanded', "true");

            $('header .site__header__content button').not(this).attr('aria-expanded', "false");

        }
    });


    // Search Dropdown
    $(".toggle-search").click(function () {

        // If open
        if ($body.hasClass("search--open")) {
            $body.alterClass('*--open', '');
            $(this).attr('aria-expanded', "false");
            $(".site__header__search").slideUp();

            // If not open
        } else if (!$body.hasClass("search--open")) {
            $body.alterClass('*--open', 'search--open');
            $(".site__header__mega").slideUp();

            $(".site__header__search").slideDown();
            $(this).attr('aria-expanded', "true");

            $('header .site__header__content button').not(this).attr('aria-expanded', "false");

        }
    });

    // Close search dropdown when click outside
    const target = document.querySelector('header');

    document.addEventListener('click', (event) => {
        const withinBoundaries = event.composedPath().includes(target)

        if (!withinBoundaries) {
            $html.removeClass('overlay');
            $html.alterClass('*--open', '');
            $html.removeClass('noscroll');
            $(".site__header__search").slideUp();
            $('header button').not(this).attr('aria-expanded', "false");
        }
    });

    // Close Dropdowns with ESC
    $(document).on('keydown', function (event) {
        if (event.key === "Escape") {
            $html.removeClass('overlay');
            $html.alterClass('*--open', '');
            $html.removeClass('noscroll');
            $('header button').not(this).attr('aria-expanded', "false");
        }
    });

    // 100% Mobile VH fix
    function calculateVh() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + 'px');
    }

    // Initial calculation
    calculateVh();

    // Re-calculate on resize
    window.addEventListener('resize', calculateVh);

    // Re-calculate on device orientation change
    window.addEventListener('orientationchange', calculateVh);

    // Show / Hide Toggle
    $(".show_hide").on("click", function (e) {
        $(this).text($(this).text() === 'More' ? 'Less' : 'More');
        $(this).prev('.text').toggleClass('hide');
        e.preventDefault();
    });

    $('.listelement__counter').rCounter();

    // List view mode
    $(".mode--list").click(function () {
        $(this).closest("nav").find("li").removeClass("active");
        $(this).parent().addClass("active");
        $(this).closest(".block").find(".list").alterClass('view--*', 'view--list');
        $(this).closest(".block").find(".listelement").alterClass('col-*', 'col-12');
    });

    $(".mode--grid").click(function () {
        $(this).closest("nav").find("li").removeClass("active");
        $(this).parent().addClass("active");
        $(this).closest(".block").find(".list").alterClass('view--*', 'view--grid');
        $(this).closest(".block").find(".listelement").alterClass('col-*', 'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3');
    });
    
    const breadcrumb = document.querySelector('[aria-label="breadcrumb"]');
    if( breadcrumb instanceof HTMLElement ) {
        [...breadcrumb.querySelectorAll('li.breadcrumb-item > a')].map(anchor => {
            anchor.addEventListener('click', breadcrumbHandler);
        });
    }
}


// Toggle Read More (Category Page)
// Find the .block__manufacturer container
const categoryContainer = document.querySelector('.block__shop__product__list');

// Check if the container exists
if (categoryContainer instanceof HTMLElement) {
    // Find the #toggleReadMore and .truncate-text within the container
    const toggleButton = categoryContainer.querySelector('#toggleReadMore');
    const textContainer = categoryContainer.querySelector('.truncate-text');

    // Check if both elements exist
    if (toggleButton instanceof HTMLElement && textContainer instanceof HTMLElement) {
        const lineHeight = parseInt(window.getComputedStyle(textContainer).lineHeight);
        const maxHeight = 3 * lineHeight; // Assuming 3 lines as the threshold for truncation

        if (textContainer.scrollHeight > maxHeight) {
            toggleButton.style.display = 'block'; // Show the toggle button only if truncation is needed

            toggleButton.addEventListener('click', function () {
                if (textContainer.classList.contains('expanded')) {
                    textContainer.classList.remove('expanded');
                    toggleButton.textContent = "Les mer";
                } else {
                    textContainer.classList.add('expanded');
                    toggleButton.textContent = "Vis mindre";
                }
            });
        } else {
            toggleButton.style.display = 'none'; // Hide the toggle button if truncation is not needed
        }
    }
}


$(document).ready(function () {
    if ($('.block__manufacturer').length > 0) {
        updateTextHeight();

        $(window).resize(function () {
            updateTextHeight();
        });

        $('.read-more-link').click(function () {
            $(this).parent().prev('.text').css('max-height', 'none').addClass('text--expanded').removeClass('text--not-expanded');;
            $(this).hide();
        });
    }
});

function updateTextHeight() {
    var imgHeight = $('#makerlogo').height();
    $('.block__manufacturer .text').css('max-height', imgHeight + 'px');

    // Check if text is truncated
    $('.block__manufacturer .text').each(function () {
        var $text = $(this);
        // console.log('Inside each loop');
        // console.log('scrollHeight:', this.scrollHeight);
        // console.log('clientHeight:', this.clientHeight);
        if (this.scrollHeight > this.clientHeight) {
            // console.log('Text is truncated');
            $text.addClass('text--truncated');
            $text.next('.read-more').show();
        } else {
            $text.removeClass('text--truncated');
            $text.next('.read-more').hide();
        }
    });
}



// // Toggle Read More (Manufacturer page)
// // Find the .block__manufacturer container
// const manufacturerContainer = document.querySelector('.block__manufacturer');

// // Check if the container exists
// if (manufacturerContainer instanceof HTMLElement) {
//     // Find the #toggleReadMore and .truncate-text within the container
//     const toggleButton = manufacturerContainer.querySelector('#toggleReadMore');
//     const textContainer = manufacturerContainer.querySelector('.truncate-text');

//     // Check if both elements exist
//     if (toggleButton instanceof HTMLElement && textContainer instanceof HTMLElement) {
//         const lineHeight = parseInt(window.getComputedStyle(textContainer).lineHeight);
//         const maxHeight = 5 * lineHeight; // Assuming 5 lines as the threshold for truncation

//         if (textContainer.scrollHeight > maxHeight) {
//             toggleButton.style.display = 'block'; // Show the toggle button only if truncation is needed

//             toggleButton.addEventListener('click', function () {
//                 if (textContainer.classList.contains('expanded')) {
//                     textContainer.classList.remove('expanded');
//                 } else {
//                     textContainer.classList.add('expanded');
//                 }
//             });
//         } else {
//             toggleButton.style.display = 'none'; // Hide the toggle button if truncation is not needed
//         }
//     }
// }


// Check whether the fist element / block is a hero section
document.addEventListener("DOMContentLoaded", function() {
  const content = document.querySelector("#content");
  if ( content !== null ) {
    const firstChild = content.firstElementChild;
    if (firstChild && firstChild.classList.contains("block__hero")) {
      document.body.classList.add("hero");
    }
  }
});


// Toggle password visibility
document.addEventListener("DOMContentLoaded", function() {
  const passwordInput = document.getElementById('passwordInput');
  const toggleButton = document.getElementById('toggleVisibilityBtn');
  
  if( toggleButton !== null && passwordInput !== null ) {
      toggleButton.addEventListener('click', function() {
          if (passwordInput.type === 'password') {
              passwordInput.type = 'text';
          } else {
              passwordInput.type = 'password';
          }
      });
  }
});


// Custom form validation because of custom form field container structure
$(document).ready(function() {
  $('.needs-validation').on('submit', function(event) {
      if (!this.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
      }
      $(this).addClass('was-validated');
  });
});


// Manufacturer block - Expand text
document.addEventListener("DOMContentLoaded", function() {
    let textDiv = document.querySelector(".block__manufacturer .text");
    let figure = document.querySelector(".block__manufacturer figure");
    let expandTextButton = document.getElementById("expand-text");

    function adjustTextHeight() {
        if (textDiv && textDiv.style.maxHeight !== "none") {
            textDiv.style.maxHeight = figure.offsetHeight + "px";
        }
    }

    // Initialize height on load
    adjustTextHeight();

    // Expand content on button click
    if (expandTextButton) {
        expandTextButton.addEventListener("click", function () {
            textDiv.style.maxHeight = "none";
            textDiv.classList.add("expanded");  // Add the class here
            expandTextButton.style.display = "none";  // Hide the link after expanding
        });
    }

    // Adjust the height on window resize
    window.addEventListener("resize", adjustTextHeight);
});

const breadcrumbHandler = (e) => {
    // prevent active element redirection
    if( e.target.parentNode.classList.contains('active') ) {
        e.preventDefault();
        e.stopPropagation();
    }
};


document.addEventListener('DOMContentLoaded', function () {
    var clickableElement = document.querySelector('.toggle-nav');

    // Check if the element with the class exists
    if (clickableElement) {
      clickableElement.addEventListener('click', function (event) {
        // Prevent the default behavior of the <a> element
        event.preventDefault();

        // Toggle the 'active' class on the clickable element
        this.classList.toggle('active');

        // Find the parent .offcanvas element
        var offcanvasParent = this.closest('.offcanvas');

        // Toggle the 'offcanvas-active' class on the parent .offcanvas element
        if (offcanvasParent) {
          offcanvasParent.classList.toggle('nav--open');
        }
      });
    }
});


document.addEventListener('DOMContentLoaded', function () {
    const fileInputs = [...document.querySelectorAll( 'input[type="file"]' )];
    fileInputs.forEach(function(input) {
        const label = input.nextElementSibling || input.previousElementSibling;
        if (! label) {
            return;
        }
        const labelVal = label.innerHTML;

        input.addEventListener( 'change', function( e ) {
            let fileName = '';
            if( this.files && this.files.length > 1 ) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            } else {
                fileName = this.files[0].name;
            }
            if( fileName ) {
                label.querySelector('span, p').innerHTML = fileName;
            } else {
                label.innerHTML = labelVal;
            }
        });
    });
});


// Truncate breadcrumb
(function($, window, document, undefined) {
    // Create the defaults once
    var pluginName = 'breadcrumbs',
      pluginCssBlock = '.' + pluginName,
      cssExpanded = pluginName + '_expanded',
      cssToggle = pluginName + '__toggle',
      cssOverflow = pluginName + '_overflow',
      defaults = {
        /*propertyName: "value"*/
      };
  
    // The actual plugin constructor
    function Plugin(element, options) {
      this.element = element;
      this.$element = $(element);
      this.options = $.extend({}, defaults, options);
      this._defaults = defaults;
      this._name = pluginName;
  
      this.init();
    }
  
    Plugin.prototype = {
  
      init: function() {
        this.injectExpander(this.$element);
        this.checkOverflow();
        var self = this;
  
        var lazyResize = $.proxy(this.debounce(this.checkOverflow, 300), self);
        var lazyToggle = $.proxy(this.doToggle, self);
        this.$element.on('click.' + pluginName, '.' + cssToggle, lazyToggle);
        $(window).on('resize.' + pluginName, lazyResize);
      },
  
      isOverflowed: function(el) {
        // Determine if we have overflow
        return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
      },
  
      checkOverflow: function() {
        if (this.isOverflowed(this.$element.parent()[0])) {
          this.$element.addClass(cssOverflow);
        } else {
          this.$element.removeClass(cssOverflow);
          // double check to make sure that the toggle is still valid
          if (this.isOverflowed(this.$element.parent()[0])) {
            this.$element.addClass(cssOverflow);
          }
        }
      },
  
      doCollapse: function() {
        this.$element.addClass(cssExpanded);
      },
  
      doExpand: function() {
        this.$element.removeClass(cssExpanded);
      },
  
      doToggle: function(evt) {
        evt.preventDefault();
        var $el = this.$element;
  
        if ($el.hasClass(cssExpanded)) {
          this.doExpand();
        } else {
          this.doCollapse();
        }
      },
  
      injectExpander: function($el) {
        if (!$el.has(cssToggle).length) {
          $el
            .children(':first')
            .after($('<li class="' + cssToggle + '"><a href="#"></a></li>'));
        }
      },
  
      debounce: function(func, wait, immediate) {
        var timeout, args, context, timestamp, result;
  
        var now = Date.now || function() {
          return new Date().getTime();
        };
  
        var later = function() {
          var last = now() - timestamp;
  
          if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
          } else {
            timeout = null;
            if (!immediate) {
              result = func.apply(context, args);
              if (!timeout) context = args = null;
            }
          }
        };
  
        return function() {
          context = this;
          args = arguments;
          timestamp = now();
          var callNow = immediate && !timeout;
          if (!timeout) timeout = setTimeout(later, wait);
          if (callNow) {
            result = func.apply(context, args);
            context = args = null;
          }
  
          return result;
        };
      }
    };
  
    $.fn[pluginName] = function(options) {
      return this.each(function() {
        if (!$.data(this, 'plugin_' + pluginName)) {
          $.data(this, 'plugin_' + pluginName,
            new Plugin(this, options));
        }
      });
    };
  
    // AutoInit based on predefined class (.breadcrumbs). Remove if you don't want this
    $(document).ready(function() {
      $(pluginCssBlock)[pluginName]();
    });
  
  })(jQuery, window, document);


// Scroll to top button
window.onscroll = function() {
  scrollFunction();
};

function scrollFunction() {
  var scrollToTopBtn = document.getElementById("scrollToTopBtn");

  if (scrollToTopBtn) {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      scrollToTopBtn.classList.remove("hidden");
    } else {
      scrollToTopBtn.classList.add("hidden");
    }
  }
}

var scrollToTopBtn = document.getElementById("scrollToTopBtn");

if (scrollToTopBtn) {
  scrollToTopBtn.addEventListener("click", function() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  });
}


// Sticky header filter
document.addEventListener('DOMContentLoaded', function() {
  const header = document.querySelector('.site__header');
  const filter = document.querySelector('.block__header__footer');
  const wrapper = document.querySelector('.block__header__footer > .block__header__wrapper');

  function checkScroll() {
      if (header && filter && wrapper) {
          const headerHeight = header.offsetHeight;
          const filterTop = filter.getBoundingClientRect().top;

          if (filterTop <= headerHeight) {
              filter.classList.add('sticky');
          } else {
              filter.classList.remove('sticky');
          }
      }
  }

  checkScroll();

  window.addEventListener('scroll', checkScroll);
  window.addEventListener('resize', checkScroll);
});